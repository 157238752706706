import { HTTP_METHOD_POST, SUBMIT_IDEA_API } from "../constants/apiConstants"

export default function submitIdeaAPI(email, name, company, description) {
    fetch(SUBMIT_IDEA_API, {
        method: HTTP_METHOD_POST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            name: name,
            company: company,
            description: description
        })
    }).then(res => {
        console.log(res)
    }).catch(err => {
        console.log(err)
    })
}