import React from "react";
// import lin from "../assets/icons8-linked-in-48.png";
import team from "../assets/team.jpg";
// import profile from "../assets/profile.png";
const Team = () => {
  return (
    <section className=" py-7" id="Team">
      <div className=" pl-16 max-sm:pl-0 flex items-center">
        <div>
          <h1 className=" font-bold pl-7 text-2xl">Meet The Team</h1>
          <p className=" px-7 w-[] py-4 text-slate-600 max-sm:w-full">
            "Get to know the faces behind Sadhikara. Meet our dedicated core
            team who are passionate about supporting and empowering
            entrepreneurs. Learn more about their expertise, experience, and
            commitment to driving innovation forward. Discover the people who
            make Sadhikara a trusted partner in your startup journey."
          </p>
        </div>
        <img src={team} alt="" className=" w-[50%] max-sm:hidden" />
      </div>
      {/* <div className="flex overflow-scroll justify-evenly">
        <div className="card">
          <img src={profile} alt="" />
          <h1 className="flex align-baseline ml-7 max-sm:w-[200px] max-sm:items-center">
            Jitendar Reddy Mitta
            <a href="https://www.linkedin.com/in/mittajithendra/">
              <img
                src={lin}
                alt=""
                style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              />
            </a>
          </h1>
          <p>Founder, SDE</p>
        </div>
        <div className="card">
          <img src={profile} alt="" />
          <h1 className="flex align-baseline ml-7 max-sm:w-[200px] max-sm:items-center">
            Chandra Teja Reddy G
            <a href="https://www.linkedin.com/in/chandra-teja-reddy-112a77187/">
              <img
                src={lin}
                alt=""
                style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              />
            </a>
          </h1>
          <p>Founder, Data Architect</p>
        </div>
        <div className="card">
          <img src={profile} alt="" />
          <h1 className="flex align-baseline ml-10 max-sm:w-[180px] max-sm:items-center">
            Trived Chinchula
            <a href="https://www.linkedin.com/in/trived-ch/">
              <img
                src={lin}
                alt=""
                style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              />
            </a>
          </h1>
          <p>Founder, SDE(Frontend)</p>
        </div>
        <div className="card">
          <img src={profile} alt="" />
          <h1 className="flex align-baseline ml-7 max-sm:w-[200px] max-sm:items-center">
            Varun Sai Dakavaram
            <a href="https://www.linkedin.com/in/varun-sai/">
              <img
                src={lin}
                alt=""
                style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              />
            </a>
          </h1>
          <p>Founder, SDE</p>
        </div>
      </div> */}
    </section>
  );
};

export default Team;
