import React, { useState } from "react";
import submitIdeaAPI from "../api/submitIdeaAPI";

const Form = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");

  const onSubmit = () => {
    submitIdeaAPI(email, name, company, description);
  }
  return (
    <div
      style={{ animation: "slideInFromLeft 1s ease-out;" }}
      className="max-w-md w-full bg-white relative rounded-xl shadow-2xl overflow-hidden p-8 space-y-8 max-sm:w-[94%] mb-3"
    >
      <h2
        style={{ animation: " appear 2s ease-out;" }}
        className="text-center text-2xl font-bold"
      >
        Submit Your Idea
      </h2>
      <p style={{ animation: "appear 3s ease-out;" }} className="text-center ">
        We would love to hear from you. Please fill out the form below to get in
        touch with one of our team members.
      </p>
      <form
        class="space-y-6"
        action="#idea"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit()
        }}
      >
        <div class="relative">
          <input
            placeholder="john@example.com"
            className="peer h-10 w-full border-gray-300 bg-transparent placeholder-transparent focus:border-b-2 focus:outline-none"
            required
            name="name"
            id="name"
            type="text"
            onChange={(e) => setName(e.target.value)}
          />
          <label
            className="absolute left-0 -top-3.5 text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-sm"
            for="name"
          >
            Name *
          </label>
        </div>
        <div class="relative">
          <input
            placeholder="text"
            class="peer h-10 w-full  border-gray-300  bg-transparent placeholder-transparent focus:border-b-2 focus:outline-none"
            required
            id="email"
            name="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <label
            class="absolute left-0 -top-3.5 text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5  peer-focus:text-sm"
            for="password"
          >
            Email *
          </label>
        </div>
        <div class="relative">
          <input
            placeholder="text"
            class="peer h-10 w-full  border-gray-300 bg-transparent placeholder-transparent  focus:border-b-2 focus:outline-none"
            required=""
            id="company"
            name="company"
            type="text"
            onChange={(e) => setCompany(e.target.value)}
          />
          <label
            class="absolute left-0 -top-3.5 text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-sm"
            for="company"
          >
            Company
          </label>
        </div>
        <div class="relative mt-6">
          <input
            placeholder="text"
            class="peer h-14 w-full  border-gray-300 bg-transparent placeholder-transparent  focus:border-b-2 focus:outline-none"
            required=""
            id="idea"
            name="idea"
            type="text"
            onChange={(e) => setDescription(e.target.value)}
          />
          <label
            class="absolute left-0 -top-3.5 text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-sm"
            for="idea"
          >
            Describe Your Idea *
          </label>
        </div>

        <button
          class="w-full py-2 px-4 bg-[#151534] rounded-md shadow-lg text-white font-semibold transition duration-200"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form;
