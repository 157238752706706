import React from "react";
import "../";

const Hero = () => {
  return (
    <section className="hero bg-gradient-to-t from-[#7eab37] to-[#01457a] h-[400px] leading-10 text-white flex flex-col justify-center items-center">
      <h1 className=" text-3xl max-sm:text-2xl text-center">
         Where Innovation Thrives: Turning Ideas into Enterprise
      </h1>
      <div className="flex space-x-4 items-center mt-5 max-sm:flex-col text-md">
        <a href="#idea">
          <button
            className=" border-2 border-white bg-white text-slate-700 h-[60px] w-[150px] rounded-full hover:bg-[#f9ff8f] max-sm:h-[40px] max-sm:w-[150px] max-sm:flex max-sm:items-center max-sm:justify-center
                          "
          >
            Pitch Your Idea
          </button>
        </a>
      </div>
    </section>
  );
};

export default Hero;
