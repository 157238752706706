import React from "react";
import logo6 from "../assets/logo-6.png";
const Companie = () => {
  return (
    <section
      className=" min-h-[70vh] bg-gradient-to-tl from-[#ffffff] to-[#d2e8b1cb]"
      id="companie"
    >
      <center>
        <h2 className=" pt-6 text-  text-2xl font-bold">
          Our Partnered Companies
        </h2>
      </center>
      <div className="flex flex-wrap gap-[100px] px-[100px] py-20 justify-center">
        <a href="https://flyamore.sadhikara.com/">
          <div className="h-[210px] w-[210px] max-sm:h-[160px] max-sm:w-[160px] rounded-full bg-white shadow-lg border-2 flex justify-center items-center hover:scale-125 transition duration-300">
            <img src={logo6} alt="" className=" scale-150 pt-3" />
          </div>
        </a>
        {/* <a href="#">
          <div className="h-[210px] w-[210px] max-sm:h-[160px] p-4 max-sm:w-[160px] rounded-full bg-white shadow-lg border-2 flex justify-center items-center hover:scale-125 transition duration-300">
            <img src={logo1} alt="" className="" />
          </div>
        </a> */}
      </div>
    </section>
  );
};

export default Companie;
