import { HTTP_METHOD_POST, SUBSCRIBE_EMAIl_API } from "../constants/apiConstants"
import { SOURCE_SADHIKARA } from "../constants/stringConstants"

export default function subscribeEmailAPI(email) {
    fetch(SUBSCRIBE_EMAIl_API, {
        method: HTTP_METHOD_POST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            source: SOURCE_SADHIKARA,
            subscribeEpoch: Date.now(),
            isSubscribed: true
        })
    }).then(res => {
        console.log(res)
    }).catch(err => {
        console.log(err)
    })
}