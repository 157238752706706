import React from "react";
import ill from "../assets/ill1.png";
const About = () => {
  return (
    <>
      <section
        id="about"
        className="flex justify-center items-center w-full space-x-5 h-[65vh] max-sm:h-auto flex-wrap"
      >
        <img
          src={ill}
          alt=""
          className="w-[540px] max-md:w-[300px] max-sm:hidden"
        />
        <div className="w-[40%] max-sm:w-[85%] max-sm:pb-7">
          <h2 className=" pb-4  font-bold text-2xl max-sm:pt-4">Who We Are</h2>
          <img
            src={ill}
            alt=""
            className="w-[540px] max-md:w-[300px] hidden max-sm:block"
          />

          <p className=" text-slate-600">
            "At Sadhikara, we are more than just a platform. We are a dedicated
            team committed to empowering innovators and entrepreneurs to turn
            their visions into reality. Our mission is to provide the support
            and resources needed for startup success, connecting ambitious
            individuals with the investors and partners who share their passion
            for innovation. With a focus on collaboration, integrity, and
            excellence, we strive to be the catalyst for transformative ideas
            and ventures. Get to know the heart and soul behind Sadhikara, and
            discover how we can help you on your entrepreneurial journey."
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
