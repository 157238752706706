import React from "react";
import logo from "../assets/logo.png";
import { useState, useEffect } from "react";
import menu from "../assets/menu.png";

const Nav = () => {
  const [item, setitem] = useState("home");
  let sw = window.innerWidth;
  const [tog, settog] = useState(true);
  useEffect(() => {
    if (sw < 400) {
      settog(false);
    }
  }, [sw]);
  return (
    <nav className=" h-[80px] border-b-2 z-50 border-slate-300 flex sticky top-0 items-center justify-between bg-[#FAF9FE] px-10 max-sm:px-2">
      <img src={logo} alt="" className=" h-[169px] w-[173px]" />
      <img
        src={menu}
        onClick={() => settog(!tog)}
        className=" hidden max-sm:block max-sm:pr-3"
        alt="menu"
      />
      {tog ? (
        <ul className=" flex space-x-10 pr-9 text-md max-sm:absolute max-sm:flex-col bg-[#FAF9FE] max-sm:right-0 max-sm:top-20 max-sm:py-5 max-sm:ease-in">
          {/* <li
            onClick={() => setitem("home")}
            className={item === "home" ? "border-b-2 border-red-500" : ""}
          >
            <a href="#home">Home</a>
          </li> */}
          <li
            onClick={() => setitem("about")}
            className={item === "about" ? "border-b-2 border-red-500" : ""}
          >
            <a href="#about">Who We Are</a>
          </li>
          <li
            onClick={() => setitem("companie")}
            className={item === "companie" ? "border-b-2 border-red-500" : ""}
          >
            <a href="#companie">Our Portfolio</a>
          </li>
          <li
            onClick={() => setitem("Team")}
            className={item === "Team" ? "border-b-2 border-red-500" : ""}
          >
            <a href="#Team">Team</a>
          </li>
          <li
            onClick={() => setitem("idea")}
            className={item === "idea" ? "border-b-2 border-red-500" : ""}
          >
            <a href="#idea">Get In Touch</a>
          </li>
          <li
            onClick={() => setitem("contact")}
            className={item === "contact" ? "border-b-2 border-red-500" : ""}
          >
            <a href="#footer">Contact</a>
          </li>
        </ul>
      ) : (
        ""
      )}
    </nav>
  );
};

export default Nav;
