import Nav from "./components/Nav";
import Hero from "./components/Hero";
import About from "./components/About";
import Companie from "./components/Companie";
import Idea from "./components/Idea";
import Team from "./components/Team";
import Footer from "./components/Footer";
export default function App() {
  return (
    <>
      <Nav />
      <section className=" fixed top-[80px] overflow-auto h-[90vh] scroll-smooth w-[100%]">
        <Hero />
        <About />
        <Companie />
        <Team />
        <Idea />
        {/* <NewsLetter /> */}
        <Footer />
      </section>
    </>
  );
}
