import React, { useState } from "react";
// import facebook from "../assets/icons8-facebook-48.png";
import inst from "../assets/icons8-instagram-48.png";
import twit from "../assets/icons8-twitterx-48.png";
import linked from "../assets/icons8-linked-in-48.png";
import { SUBSCRIBE_TO_NEW_LETTER_TXT } from "../constants/stringConstants";
import subscribeEmailAPI from "../api/subscribeEmailAPI";
const Footer = () => {
  const [subscribeEmail, setSubscribeEmail] = useState("");

  const onSubmit = ()=>{
    subscribeEmailAPI(subscribeEmail);
  }
  return (
    <footer
      id="footer"
      className="px-4 min-h-[40vh] border-t-2 pt-2 leading-10 bg-slate-900 text-white max-sm:px-0"
    >
      <div className=" px-5 space-x-10 pb-0 max-sm:justify-between max-sm:px-4">
        <div className="flex justify-between items-center max-sm:flex">
          <div>
            <ul className="flex space-x-3 pl-3 max-sm:pt-6 max-sm:space-x-5">
              <li>
                <a href="https://www.instagram.com/sadhikara.innovate?igsh=MWt0NnNxcDd1endpeA==">
                  <img src={inst} alt="" className=" max-sm:scale-[2]" />
                </a>
              </li>
              <li>
                <a href="https://x.com/sadhikara?t=FYmVhOatTXMysw4HunpeKA&s=08">
                  <img src={twit} alt="" className=" max-sm:scale-[2]" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/sadhikara/">
                  <img src={linked} alt="" className=" max-sm:scale-[2]" />
                </a>
              </li>
            </ul>
          </div>

          <div className=" text-center max-sm:py-1">
            <h1 className=" max-sm:text-sm text-white max-sm:text-[10px]">
              {SUBSCRIBE_TO_NEW_LETTER_TXT}
            </h1>
            <div className="flex bg-transparent relative max-sm:scale-75">
              <input
                type="text"
                className=" bg-transparent border-[1px] px-5 rounded-full h-[50px] w-[320px] border-white max-sm:w-[220px]"
                placeholder="Email"
                onChange={(event)=>setSubscribeEmail(event.target.value)}
              />
              <button className=" border-2 bg-[#151534] text-white w-[50px] h-[50px] rounded-full  flex items-center justify-center absolute right-0 top-0 " onClick={onSubmit}>
                <span class="material-symbols-outlined">arrow_forward</span>
              </button>
            </div>
          </div>
        </div>

        <br />
        <h2 className=" relative left-[-20px]  text-xl font-bold text-white">
          Where to find us
        </h2>
      </div>
      <ul className=" px-10 py-0 space-y-[-20px]">
        <li>Plot 125, Whitefield</li>
        <li>Bengaluru, Karnataka</li>
        <li>India, 560066.</li>
      </ul>

      <br />
      <div className="relative">
        <ul
          className="flex border-none space-x-2 px-10 max-sm:px- max-sm:flex-wrap max-sm:space-x-0 text-white
        "
        >
          <a href="/privacy.html">
            <li className=" max-sm:pr-1">Privacy Policy</li>
          </a>
          <li>| Disclosures |</li>
          <li>2024 ©Sadhikara All rights reserved</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
