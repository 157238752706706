import React from "react";
import "../index.css";
import Form from "./Form";

const Idea = () => {
  return (
    <section
      id="idea"
      className="flex justify-center items-center w-full space-x-5 max-sm:space-x-0 min-h-[90vh] bg-gradient-to-tl from-[#ffffff] to-[#d2e8b1cb]  flex-wrap"
    >
      <div className="w-[40%] max-sm:w-[90%] max-sm:pb-4 max-sm:pt-7 max-sm:mr-2 max-sm:text-center mr-[100px]  max-md:pl-5">
        <h2 className=" font-bold text-2xl pb-4 max-sm:text-start">
          Find Your Next Investor
        </h2>
        <p className=" text-slate-600 font-[poppins] leading-7 max-sm:text-start">
          "Sadhikara is your gateway to securing the support and resources
          needed to bring your innovative ideas to life. Explore our platform to
          connect with potential investors who are eager to back your vision and
          propel your startup journey forward. With Sadhikara, your
          entrepreneurial aspirations are within reach."
        </p>
      </div>
      <Form />
    </section>
  );
};

export default Idea;
